.Background {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #F0F4FD;
}

.Background2 {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
}
.Area {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 25px;
    margin-right: 20px;
}
