.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}
ul {
    list-style: none;
    padding: 0;
}
ul.pagination li {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
ul.pagination li:first-child {
    width: 26px;
    height: 26px;
    border: 1px solid rgba(158, 163, 207, 1);
}
ul.pagination li:last-child {
    width: 26px;
    height: 26px;
 border: 1px solid rgba(158, 163, 207, 1); 
}
ul.pagination li a {
    text-decoration: none;
    color: rgba(161, 166, 208, 1);
    font-size: 16px;
}
ul.pagination li.active a {
    color: #101010;
    font-size: 16px;
}
ul.pagination li.active {
    background-color: none;
}
ul.pagination li a.active,
ul.pagination li a:hover {
    color: #5A62AD;
}

ul.pagination .lt {
    padding-top: 0px;
    width: 28px;
    height: 28px;
    margin-top: 0px;
    font-size: 14px;
    color: rgba(161, 166, 208, 1);
    background-color: rgba(244, 245, 250, 1);
    border: 1px solid rgba(161, 166, 208, 1);
}
ul.pagination .lt:hover {
    color: #5A62AD;
}

ul.pagination .gt {
    padding-top: 0px;
    width: 28px;
    height: 28px;
    margin-top: 0px;
    font-size: 14px;
    color: rgba(161, 166, 208, 1);
    background-color: rgba(244, 245, 250, 1);
    border: 1px solid rgba(161, 166, 208, 1);
}
ul.pagination .gt:hover {
    color: #5A62AD;
}
.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}