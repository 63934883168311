.PhraseBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
}

.PhraseBody .Overlap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.PhraseBody .Right {
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.PhraseBody .Box {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
    overflow: auto;
    background-color: #FFFFFF;
}

.PhraseBody .Translate {
    flex: 1;
    overflow: auto;
    padding: 19px;
}
