.Header {
    height: 12rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
@media (height <= 900px) {
    .Header {
        height: 7rem;
    }
}

.Header .Container {
    padding: 0px 420px;
}
@media (width < 1920px) {
    .Header .Container {
        padding: 0px 150px;
    }
}
@media (width < 1280px) {
    .Header .Container {
        padding: 0px 10px;
    }
}

.Header .Container .NaviArea {
    height: 9.8rem;
    display: flex;
    align-items: flex-end;
}
@media (height <= 900px) {
    .Header .Container .NaviArea {
        height: 6.9rem;
    }
}

.NaviArea .Logo {
    flex: 1;
    height: 100%;
}
.NaviArea .Tab {
    flex: 2;
}
.NaviArea .User {
    flex: 1;
    height: 100%;
}

.Header .Divider {
    position: absolute;
    width: 100%;
    height: 2.2rem;
    bottom: 0;
    background-color: #CFD3F6;
}
@media (height <= 900px) {
    .Header .Divider {
        height: 0.1rem;
        bottom: 0;
    } 
}

.Footer {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background-color: #DADCE9;
}

.Footer .Container {
    height: 4.8rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 420px;
}
@media (width < 1920px) {
    .Footer .Container {
        padding: 0px 150px;
    }
}
@media (width < 1280px) {
    .Footer .Container {
        padding: 0px 10px;
    }
}

.Footer .NaviArea {
}

.Content {
    position: fixed;
    left: 0;
    top: 12rem;
    right: 0;
    bottom: 4.8rem;
    background-color: #F4F5Fa;
}
@media (height <= 900px) {
    .Content {
        top: 7rem;
    }
}

.Content .Container {
    height: 100%;
    padding: 0px 420px;
}
@media (width < 1920px) {
    .Content .Container {
        padding: 0px 150px;
    }
}
@media (width < 1280px) {
    .Content .Container {
        padding: 0px 10px;
    }
}
