.Sentence {
    height: 45%;
    display: flex;
    flex-direction: row;
    overflow: auto;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
}

.Sentence .Form {
    padding-left: 2px;
    padding-right: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Sentence .FormBox {
    width: 23px;
    min-height: 23px;
    display: flex;
    justify-content: center;
    background-color: #5A62AD;
}

.Sentence .Words {
    display: flex;
    flex-direction: row;
}

.Words .Word {
    padding-left: 2px;
    padding-right: 2px;
}

.Words .First {
    padding-left: 0px;
    padding-right: 2px;
}

.Words .Last {
    padding-left: 2px;
    padding-right: 0px;
}
