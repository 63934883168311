.NoticeHeader {
    width: 100%;
    height: 120px;
    position: fixed;
    top: 100;
    z-index: 1;
}

.NoticeBody {
    width: 100%;
    position: relative;
    z-index: 1;
}

.NoticeBody .Table {
    width: 100%;
    height: 62vh;
    position: relative;
    top: 10px;
    z-index: 1;
    border-radius: 0px;
    /* overflow: scroll; */
}

 .NoticeDetailTable {
    width: 100%;
    height: 50vh;
    position: relative;
    top: 10px;
    z-index: 1;
    border-radius: 0px;
    /* overflow: scroll; */
}