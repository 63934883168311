.Header {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: #E2E4F1;
    border-radius: 5px 5px 0 0;
}

.Header .Title {
    margin-left: 25px;
}
