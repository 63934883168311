.PhraseTree {
    height: 60%;
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.PhraseTree .Tree {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.PhraseTree .Tree .Overlap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.PhraseTree .Tree .ButtonArea {
    height: 100%;
    margin-right: 20px;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}

.PhraseTree .Control {
    margin-left: 10px;
    width: 220px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
}

.PhraseTree .Control .ButtonArea {
    flex: 1.5;
    margin-top: 9px;
}

.PhraseTree .Layout {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.PhraseTree .TreeLayout {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.PhraseTree .Header {
    display: flex;
    flex-direction: row;
}

.PhraseTree .vdivider {
    width: 1px;
    Background-color: #dadada;
}
