
.EngDicBox {
  font-size: 14px ;
}

.EngDicBox h3{
  margin-block-start: 0em;
  margin-block-end: 0em;
}


.EngDicBox .t1 a{
  font-size: 1.6em ;
  color: #5A62AD;
  font-weight: 700;
}

.EngDicBox .t2 {
  font-size: 1em;
  color: #101010;
}

.EngDicBox .box_a {
  font-size: 1.2em;
  color: #101010;
}

.EngDicBox h4 {
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  font-size: 1em;
  color: #5f5f60;
}

.EngDicBox .box_a a{
  color: #5A62AD;
}

.EngDicBox .box_a .last {
  margin-top: 0.5em;
}

.EngDicBox em {
  visibility: hidden ;
}

.EngDicBox .desc a{
  color: #5f5f60;
  font-size: 1em;
}