.Phrase {
    flex: 1;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #FFFFFF;
}

.Phrase .Container {
    position: relative;
    height: 100%;
    padding: 5px 15px 0px 15px;
}

.Phrase .Container .Table {
    width: 0%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.Phrase .Form {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 2px;
    margin-right: 2px;
}

.Knot {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Phrase .Bottom {
    margin-bottom: 2px;
}

.Phrase .Hidden {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ECECEC;
}

.Phrase .Syntax {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Phrase .Element {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Phrase .FormBox {
    width: 23px;
    min-height: 23px;
    display: flex;
    justify-content: center;
    background-color: #5A62AD;
}
