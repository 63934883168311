.StudyHeader {
    width: 100%;
    height: 120px;
    position: fixed;
    top: 100;
    z-index: 1;
}

.StudyBody {
    width: 100%;
    position: relative;
    top: 150px;
    z-index: 1;
}

.StudyBody .Table {
    width: 100%;
    height: 52vh;
    position: relative;
    top: 10px;
    z-index: 1;
    /* overflow: scroll; */
}