.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Container .Title {
    padding: 17px 40px 17px 40px;
}

.Container .GuideArea {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Phrase {
    margin-left: 30px;
    margin-right: 30px;
}

.Phrase .mini {
    display: none;
}
@media (height <= 800px) {
    .Phrase .mini {
        display: block;
    }
}

.Phrase .large {
    display: block;
}
@media (height <= 800px) {
    .Phrase .large {
        display: none;
    }
}

.ButtonArea {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
}

.ButtonArea .Button {
    width: 220px;
    height: 53px;
    border-radius: 5px;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 280;
    height: 70;
    background-color: paper;
    display: flex;
    align-items: center;
    justify-content: center;
}
